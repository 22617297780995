import React, {Fragment, useState} from "react";
import {motion, AnimatePresence} from "framer-motion";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import styled, {createGlobalStyle} from "styled-components";
import Seo from "./Seo";
import Header from "./Header";
import Footer from "./Footer";
import Menu from "./Menu";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body {
    padding: 0;
    margin: 0;
    max-width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    color: white;
    background-color: black;
  }

  a {
    text-decoration: none;
    background-color: transparent;
    color: white;
  }

  .swiper-slide {
    border-radius: 8px !important;
    width: 150px !important;
    height: 196px !important;
    overflow: hidden !important;
    z-index: 1 !important;
  }

  @media (min-width: 1366px) {
    .swiper-slide {
      width: 180px !important;
      height: 235px !important;
    }
  }

  @media (min-width: 1440px) {
    .swiper-slide {
      width: 210px !important;
      height: 274px !important;
    }
  }

  @media (min-width: 1920px) {
    .swiper-slide {
      width: 240px !important;
      height: 313px !important;
    }
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-areas: 
    "header"
    "main"
    "footer";
  grid-template-rows: auto 1fr auto;
  min-width: 0;
  max-width: 100%;
  min-height: 100vh;
  overflow: hidden;
`;

const Main = styled.main`
  grid-area: main;
  min-width: 0;
  max-width: 100%;
  z-index: 1;
`;

const Layout = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const sections = props.data?.allDataJson?.nodes[0]?.sections || [];

  return (
    <>
      <GlobalStyle/>
      <Container>
        <Seo title={props.pageTitle}/>
        <AnimatePresence>
          {props.section === null ? (
            <motion.div
              style={{gridArea: "1/1/4/1", maxHeight: "100vh"}}
              initial={{opacity: 0}}
              animate={{opacity: 1}}
              exit={{opacity: 0}}
              transition={{duration: 0.5}}
            >
              <GatsbyImage
                style={{height: "100%"}}
                image={getImage(props.pageData.background)}
                alt=""
              />
            </motion.div>
          ) : (
            sections.map(section => {
              if (section.id === props.section) {
                return (
                  <Fragment key={section.id}>
                    <motion.div
                      style={{gridArea: "1/1/4/1", maxHeight: "100vh"}}
                      initial={{opacity: 0}}
                      animate={{opacity: 1}}
                      exit={{opacity: 0}}
                      transition={{duration: 0.5}}
                    >
                      <GatsbyImage
                        style={{height: "100%"}}
                        image={getImage(section.background)}
                        alt=""
                      />
                    </motion.div>
                  </Fragment>
                );
              } else {
                return null;
              }
            })
          )}
        </AnimatePresence>
        <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
        <AnimatePresence>
          {menuOpen && (
            <motion.div
              style={{
                position: "fixed",
                top: "60px",
                left: "0",
                width: "100%",
                zIndex: 2
              }}
              initial={{y: -300, opacity: 0}}
              animate={{y: 0, opacity: 1}}
              exit={{y: -300, opacity: 0}}
              transition={{duration: 0.3}}
            >
              <Menu setMenuOpen={setMenuOpen}/>
            </motion.div>
          )}
        </AnimatePresence>
        <Main>{props.children}</Main>
        <Footer/>
      </Container>
    </>
  );
};

export default Layout;