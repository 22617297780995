import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import Info from "../components/Info";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  max-width: 100%;
  height: 100%;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const Space = styled.div`
  padding: 20px 0 20px 20px;
  max-width: 100%;

  @media (min-width: 768px) {
    padding: 30px 0 30px 30px;
  }

  @media (min-width: 1024px) {
    width: 512px;
  }

  @media (min-width: 1280px) {
    width: 640px;
  }

  @media (min-width: 1366px) {
    width: 683px;
  }

  @media (min-width: 1440px) {
    width: 720px;
  }

  @media (min-width: 1920px) {
    width: 960px;
  }
`;

const NosotrosPage = () => {
  const data = useStaticQuery(graphql`
    {
      dataJson {
        nosotros {
          title
          description
          background {
            childImageSharp {
              gatsbyImageData(
                breakpoints: [750, 1080, 1366, 1920]
                layout: FULL_WIDTH
                placeholder: BLURRED
                quality: 80
              )
            }
          }
        }
      }
    }
  `);
  const nosotros = data.dataJson.nosotros;

  return (
    <Layout pageTitle="Nosotros" pageData={nosotros} section={null}>
      <Container>
        <Info
          isDefault={false}
          title={nosotros.title}
          description={nosotros.description}
        />
        <Space/>
      </Container>
    </Layout>
  );
};

export default NosotrosPage;