import React from "react";
import {motion} from "framer-motion";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 100%;

  @media (min-width: 768px) {
    padding: 30px;
  }

  @media (min-width: 1024px) {
    flex-grow: 1;
    justify-content: center;
  }
`;

const Card = styled.div`
  border-radius: 5px;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.3);
`;

const Title = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4rem;
  text-transform: uppercase;
  text-shadow: rgb(0 0 0 / 50%) 0 0 10px;

  @media (min-width: 1024px) {
    margin-bottom: 20px;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  @media (min-width: 1440px) {
    margin-bottom: 25px;
    font-size: 1.7rem;
    line-height: 2.1rem;
  }

  @media (min-width: 1920px) {
    margin-bottom: 30px;
    font-size: 2rem;
    line-height: 2.4rem;
  }
`;

const Description = styled(motion.div)`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4rem;
  text-align: center;
  text-shadow: rgb(0 0 0 / 50%) 0 0 10px;

  @media (min-width: 768px) {
    max-width: 560px;
  }

  @media (min-width: 1024px) {
    padding: 0 30px;
    width: 100%;
    font-size: 1.2rem;
    line-height: 1.6rem;
  }

  @media (min-width: 1440px) {
    font-size: 1.3rem;
    line-height: 1.8rem;
  }

  @media (min-width: 1920px) {
    max-width: 720px;
    font-size: 1.4rem;
    line-height: 1.9rem;
  }
`;

const Info = ({isDefault, title, description}) => {
  const titleVariants = {
    hidden: {y: 50, opacity: 0},
    visible: {
      y: 0,
      opacity: 1,
      transition: {duration: 0.5}
    },
  };

  const descriptionVariants = {
    hidden: {y: 50, opacity: 0},
    visible: {
      y: 0,
      opacity: 1,
      transition: {delay: 0.2, duration: 0.5}
    },
  };

  return (
    <Container>
      <Card>
        <Title initial="hidden" animate="visible" variants={titleVariants}>{isDefault ? title() : title}</Title>
        <Description initial="hidden" animate="visible" variants={descriptionVariants}>{description}</Description>
      </Card>
    </Container>
  );
};

export default Info;